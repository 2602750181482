import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
	<Layout>
		<SEO title="404: Not found" />
		<div className="page-hero tech-page">
			<div className="hero-content">
				<h5 data-aos="fade" data-aos-duration="300" data-aos-delay="900">
					INTERMOUNTAIN BOILERWORX INC.
				</h5>
				<h1
					data-aos="fade-up"
					data-aos-duration="600"
					data-aos-delay="1200"
					className="font-weight-bolder mt-3 mt-md-5"
				>
					404 PAGE NOT FOUND
				</h1>
				<p>You have accessed a page that doesnt exist.</p>
				<Link
					data-aos="fade"
					className="btn btn-secondary-inverted aos-init"
					to="/"
				>
					BACK TO HOME
				</Link>
			</div>
		</div>
	</Layout>
)

export default NotFoundPage
